<template>
	<div id="nav-bar">
		<div class="nav-bar-row">
			<div class="btn-mobile">
				<button @click="minimize">
					<span></span>
				</button>
			</div>
			<div class="busca row justify-end">
				 <div v-if="((company_group).find(element => element == $store.getters.user.group.key)) != undefined" class="col-12 div-input col-md-3">
					<v-select
						menu-props="offsetY"
						:items="companies"
						item-text="name" 
						item-value="uuid"
						v-model="company" 
						label="Empresa(s)"
						placeholder="Empresa(s)" 
						background-color="white"
						hide-details
						solo
						dense
						clearable
						@change="setCompanyHeader()"
					/>
				</div> 
			</div>
			<div class="menu">
				<ul>
					<li class="horario">
						{{hora}}
					</li>
					<li v-if="$store.getters.screen_size.width >= 768" class="usuario">
						<v-menu
							v-model="menu"
							:close-on-content-click="false"
							:nudge-width="200"
							offset-y
							left
							bottom
						>
							<template v-slot:activator="{ on, attrs }">
								<button 
									@click="activeMenu" 
									v-bind="attrs"
									v-on="on"
								>
									<figure>
										{{iniciais}}
									</figure>
									<i class="fas fa-chevron-down"></i>
								</button>
							</template>
							
							<v-card>
								<v-list>
									<v-list-item>
									<v-list-item-avatar style="border: solid 2px #F4D728;">
										<img 
											:src="url"
											alt=""
										>
									</v-list-item-avatar>
						
									<v-list-item-content>
										<v-list-item-title>{{$store.getters.user.name}}</v-list-item-title>
										<v-list-item-subtitle>{{$store.getters.user.email}}</v-list-item-subtitle>
									</v-list-item-content>
						
									<v-list-item-action>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: tooltip }">
												<v-btn
													icon
													@click="openEditProfile"
													color="#646669"	
													v-on="tooltip"
												>
													<i class="fas fa-user-edit"></i>
												</v-btn>
											</template>
											<span>Editar Perfil</span>
										</v-tooltip>
									</v-list-item-action>
									</v-list-item>
								</v-list>
						
								<v-divider></v-divider>
						
								<v-card-actions>
									<v-btn
										text
										@click="menu = false"
									>
										Cancelar
									</v-btn>
									<v-spacer></v-spacer>
									<v-btn
										color="primary"
										text
									>
										<a href="/logout">Sair</a>
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-menu>
					</li>
				</ul>
			</div>
		</div>
		<v-dialog v-model="dialog_edit_profile" persistent max-width="800px">
			<v-card>
				<v-card-title>
					<span class="headline"><v-icon>fa-user-edit</v-icon> Editar Perfil </span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="form_profile">
							<div class="row">
								<div class="col-3 text-center">
									<v-hover v-slot="{ hover }">
										<v-btn 
											@click="openImgPicker"
											class="mx-2"
											fab
											dark
											large
											style="height: 150px; width: 150px;"
										>
											<v-avatar 
												style="height: 150px; width: 150px;"
											>
												<v-img
													:src="url"
													alt=""
												>
													<v-expand-transition>
														<div
															v-if="hover"
															class="d-flex transition-fast-in-fast-out yellow darken-2 v-card--reveal text-h2 white--text"
															style="align-items: center;
																bottom: 0;
																justify-content: center;
																opacity: .5;
																position: absolute;
																width: 100%;"
														>
															<i class="fas fa-user-edit"></i>
														</div>
													</v-expand-transition>
												</v-img>
											</v-avatar>
										</v-btn>
									</v-hover>
								</div>
								<div class="col-9">
									<div class="row">
										<div class="col-12 div-input">
											<v-text-field 
												:rules="[v => !!v || 'Campo Nome obrigatório']" 
												v-model="profile.name" 
												label="Nome" 
												placeholder="Nome" 
												background-color="white"
												hide-details
												outlined
												prepend-inner-icon="mdi-account"
											/>
										</div>
										<div class="col-12 div-input">
											<v-text-field 
												type="email"
												:rules="[v => !!v || 'Campo E-mail obrigatório']" 
												v-model="profile.email" 
												label="E-mail" 
												placeholder="E-mail" 
												background-color="white"
												hide-details
												outlined
												prepend-inner-icon="mdi-email-outline"
											/>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-6 div-input">
									<v-text-field 
										type="password" 
										:rules="[v => (profile.uuid ? true : (v && v.length >= 8)) || 'Campo Nova Senha obrigatório (mínimo de 8 caracteres)']" 
										v-model="profile.password" 
										label="Nova Senha" 
										placeholder="Nova Senha" 
										background-color="white"
										hide-details
										outlined
										prepend-inner-icon="mdi-lock-outline"
									/>
								</div>
								<div class="col-6 div-input">
									<v-text-field 
										type="password" 
										:rules="[v => (v == profile.password) || 'As senhas não conferem']" 
										v-model="profile.password_confirmation" 
										label="Repetir Nova Senha" 
										placeholder="Repetir Nova Senha" 
										background-color="white"
										hide-details
										outlined
										prepend-inner-icon="mdi-lock-outline"
									/>
								</div>
							</div>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closeProfile">
						Cancelar
					</v-btn>
					<v-btn class="primary-button" raised small @click="saveProfile">
						Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Selecionar Imagem</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="form_avatar">
							<div class="row">
								<div class="col-12 text-center">
									<v-avatar 
										style="height: 200px; width: 200px;"
									>
										<v-img
											:src="url"
											alt=""
										/>
									</v-avatar>
								</div>
								<div class="col-12">
									<v-file-input
										:rules="[v => v != null || 'Campo Imagem obrigatório']" 
										v-model="avatarProfile"
										accept="image/png, image/jpeg, image/bmp"
										placeholder="Selecione uma imagem"
										label="Selecione uma imagem"
										prepend-icon="mdi-camera"
										hide-details
										outlined
										@change="Preview_image"
									></v-file-input>
								</div>
							</div>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-btn color="blue darken-1" text @click="dialog = false">
						Cancelar
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn class="primary-button" @click="saveImageProfile">
						Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
		<Loader v-if="loading"/>
	</div>
</template>

<script>
	import Login_icon from "@/assets/images/login-icon.png"
	import store from '@/store'
	import moment from 'moment'
	// import API from '../plugins/api'
	import Imagem from "@/assets/images/LOGO_PNG_LOGO AMARELA.png"
	// import Logo from "@/components/Logo.vue"
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'

	export default {
		name: 'NavBar',
		components: {
			DialogMensagem,
			Loader
		},
		data() {
			return {
				workspace_group: ['super'],
				company_group: ['super', 'workspace-admin', 'company-admin', 'company-agent'],
				companies: [],
				workspaces: [],
				workspace: store.getters.workspace_uuid,
				company: store.getters.company_uuid,
				hora: "",
				menu: false,
				iniciais: 'aa',
				imagem_logo: Imagem,
				profile: {
					uuid: '',
					name: '',
					email: '',
					password: '',
					password_confirmation: ''
				},
				dialog_edit_profile: false,
				dialog_resposta: false,
				dialog: false,
				avatarProfile: null,
				resposta: {},
				base_url: store.getters.user.avatar ? store.getters.user.avatar.url : Login_icon,
				url: store.getters.user.avatar ? store.getters.user.avatar.url : Login_icon,	
				loading: false,
				UID: '',
			}
		},
		methods: {
			async init(){
			if(((this.workspace_group).find(element => element == this.$store.getters.user.group.key)) != undefined){
					let dados = await store.dispatch('getWorkspacesSelect')
					this.workspaces = await dados.workspaces
			}
			this.setCompanyID()
			},
			async setCompanyID(){
				let dados = await store.dispatch('getCompaniesSelect')
				this.companies = await dados.companies
			},
			Preview_image() {
				if(this.avatarProfile != null){
					this.url = URL.createObjectURL(this.avatarProfile)
				}
			},
			async closeProfile(){
				this.url = this.base_url

				this.dialog_edit_profile = false
			},
			async saveProfile(){
				if (this.$refs.form_profile.validate()){
					this.loading = true

					const dados = await {
						// coleta o nome do usuário
						name: this.profile.name,
						// coleta o email do usuário
						email: this.profile.email,
						// coleta o email do usuário
						email_confirmation: this.profile.email,
						// coleta a senha do usuário
						password: this.profile.password,
						// coleta a senha do usuário
						password_confirmation: this.profile.password_confirmation,
						// coleta o grupo do usuário
						group: this.$store.getters.user.group.key,	
						// coleta o setor do usuário
						departments: this.$store.getters.user.departments.uuid,
						
						status: true,

						file: this.UID,
					}

					const date_update = await {
						dados_usuario: dados,
						id: this.profile.uuid,
					}

					const resp = await store.dispatch('putProfile', date_update)

					if(resp.status != 200 && resp.status != 201){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					}else{
						// atribui o título da mensagem 
						this.resposta.titulo = await  'Perfil editado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await 'Perfil editado com sucesso!'
						// mostra a mensagem
						// this.closeUsuario()
						// fecha a modal de create/edit usuário
						this.dialog_resposta = true
					}

					await store.dispatch('setUserID')

					this.dialog_edit_profile = false

					this.loading = false
				}
			},
			async saveImageProfile(){
				if (this.$refs.form_avatar.validate()){
					this.loading = true

					const dados = {
						type: 'avatar',
						filename: this.avatarProfile.name,
						contentType: this.avatarProfile.type
					}

					const resp = await store.dispatch('postImageFile', dados)


					if(resp.status != 200){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					}else{
						const data = await store.dispatch('postImageFileS3', { dados: resp.data.post, file: this.avatarProfile, fileType: this.avatarProfile.type })

						if(data.status != 204){
							// atribui o título da mensagem 
							this.resposta.titulo = await 'Algo deu errado!'
							// atribui o corpo da mensagem 
							this.resposta.mensagem = await  data.data.message || data.data.error
							// mostra a mensagem
							this.dialog_resposta = true
						}
					}

					this.UID = resp.data.file.uuid

					this.avatarProfile = null

					this.dialog = false

					this.loading = false
				}
			},
			async openImgPicker(){
				this.dialog = true
			},
			async openEditProfile(){
				this.profile.uuid = this.$store.getters.user.uuid,
				this.profile.name = this.$store.getters.user.name,
				this.profile.email = this.$store.getters.user.email,

				this.dialog_edit_profile = true
			},
			async setCompanyHeader(){
				await store.dispatch('setCompanyId', this.company)
			},	
			activeMenu() {
				this.menu = !this.menu
			},
			minimize() {
				store.dispatch('toggleMenu')
			},
			getNow: function() {
				this.hora = moment().format('H:mm:ss - DD/MM/YYYY')
			},
			setInciais: function() {
				if(this.$store.getters.user){
					var name = this.$store.getters.user.name
					var initials = name.match(/\b\w/g) || []
					this.iniciais = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
				}
			},
		},
		mounted() {
			this.init()
			setInterval(this.getNow, 1000)
			// this.setInciais()
		},
	}
</script>

<style lang="scss">
	#nav-bar{
		color: #f4f6fa;
		background-color: var(--primary-color);
		.nav-bar-row{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.btn-mobile{
				display: flex;
				padding: 10px 20px;
				button{
					color: $primaryfontcolor;
					font-size: 20px;
					display: flex;
					height: 100%;
					height: 30px;
					width: 30px;
					span{
						display: block;
						top: 50%;
						width: 30px;
						height: 3px;
						transform: translateY(-50%);
						background-color: $primaryfontcolor;
						border-radius: 4px;
						position: relative;
						transition: all ease .3s; 
						&:before,
						&:after{
							content: "";
							display: block;
							width: 100%;
							height: 100%;
							background-color: $primaryfontcolor;
							border-radius: 4px;
							position: absolute;
							transition: all ease .3s;
						}
						&:before {
							top: 7px;
							transition: all ease .3s; 
						}
						&:after {
							bottom: 7px;
						}
					}
				}
				&.open{
					button{
						span{
							transform: translate3d(0, 0px, 0) rotate(45deg);
							background-color: $primaryfontcolor;
							&:before{
								transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
								opacity: 0;
								background-color: $primaryfontcolor;
							}
							&:after{
								transform: translate3d(0, 7px, 0) rotate(-90deg);
								background-color: $primaryfontcolor;
							}
						}
					}
				}
			}
			.menu{
				display: flex;
				flex: none;
				ul{
					padding: 0;
					display: flex;
					align-items: center;
					margin-bottom: 0;
					li{
						display: inline-flex;
						padding: 10px 20px;
						button{
							background-color: transparent;
							border: none;
							color: $primaryfontcolor;
							display: flex;
						}
						&.horario{
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 20px;
							align-items: center;
							color: $primaryfontcolor;
						}
						&.usuario{
							justify-content: center;
							align-items: center;
							position: relative;
							button{
								justify-content: center;
								align-items: center;
								figure{
									margin-bottom: 0;
									margin-right: 15px;
									height: 30px;
									width: 30px;
									background-color: #fff;
									border-radius: 100%;
									font-size: 16px;
									display: flex;
									align-items: center;
									justify-content: center;
									color: $primaryfontcolor;
									border: 2px solid $primaryfontcolor;
									text-transform: uppercase;
								}
							}
							.sub-menu{
								position: absolute;
								z-index: 1;
								left: 25%;
								top: 100%;
								padding-top: 15px;
								transform: translateX(-50%);
								&:after,
								&:before{
									content: '';
									box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
									position: absolute;
								}
								&:before{
									top: 15px;
									right: 0;
									bottom: 0;
									left: 0;
									border-radius: 10px;
									// border: 1px solid red;
								}
								&:after{
									transform: rotate(45deg) translateX(-50%);
									width: 20px;
									height: 20px;
									top: 13px;
									box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
									left: 50%;
									border-radius: 5px 0;
								}
								ul{
									background-color: #fff;
									z-index: 2;
									border-radius: 10px;
									position: relative;
									&:before{
										content: '';
										background-color:  #fff;
										position: absolute;
										transform: rotate(45deg) translateX(-50%);
										width: 20px;
										height: 20px;
										top: -2px;
										left: 50%;
										border-radius: 5px 0;
									}
									a{
										color: var(--primary-color);
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>